<template>
	<div class="page">
		<div class="block30"></div>
		<div class="huaxue_info_detail relative w1239" v-if="dataobj">
			<div class="titlediv">
				<div class="title fontsize24 text-center">{{dataobj.title}}</div>
				<div class="timer fontsize14 text-center">发布时间：{{dataobj.releaseTime || dataobj.createDate}}</div>
				<div class="videodiv" v-if="dataobj.videoUrl">
					<video :src="dataobj.videoUrl" class="video" controls="controls">您的浏览器不支持视频播放</video>
				</div>
			</div>
			<div class="data_con">
				<div class="fontsize14" v-html="dataobj.contents"></div>
			</div>
			<div class="block20" v-if="dataobj.fileInfoVo&&dataobj.fileInfoVo.fileUrl"></div>
			<div class="filebox fontsize16" v-if="dataobj.fileInfoVo&&dataobj.fileInfoVo.fileUrl">
				<div class="imgicon"><img src="../assets/static/fileicn.png"/></div>
				<div class="text">附件：</div>
				<a class="a" :href="dataobj.fileInfoVo.fileUrl" target="_blank">{{dataobj.fileInfoVo.fileName}}</a>
			</div>
			<div class="block30"></div>
			<div class="huaxue_sx_page clear">
				<div class="sx_item fl">
					<div class="title fontsize14" v-html="'<&nbsp;上一篇'"></div>
					<div class="desc fontsize18" v-if="dataobj.last" @click="clickarticleDetail(dataobj.last.id)">{{dataobj.last.title}}</div>
					<div class="desc_no fontsize18" v-else>已经没有了</div>
				</div>
				<div class="sx_item fr" style="text-align: right;">
					<div class="title fontsize14" v-html="'下一篇&nbsp;>'"></div>
					<div class="desc fontsize18" v-if="dataobj.next" @click="clickarticleDetail(dataobj.next.id)">{{dataobj.next.title}}</div>
					<div class="desc_no fontsize18" v-else>已经没有了</div>
				</div>
			</div>
		</div>
		<div class="block60"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			uid:0,//详情
			dataobj:null,
		};
	},
	mounted: function() {
		if(this.$route.query&&this.$route.query.uid){
			this.uid = parseInt(this.$route.query.uid)
			this.getDataDetail()
		}else{
			this.$util.routerPath("/404")
		}
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//跳转详情
		clickarticleDetail(uid){
			this.$router.push({ path: '/infoDetail', query: {uid: uid }})
			this.$router.go(0);
		},
		//获取详情
		getDataDetail() {
			var _this = this;
			var params = {
				id:this.uid
			};
			this.$http.post('frontEnd/index/getRealTimeInfoByID', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var dataobj = res.data;
					if(dataobj){
						_this.dataobj = dataobj
					}else{
						_this.$util.routerPath("/404")
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
